// This is a generated file, do not edit!
import i18n from 'i18next';

class Copydeck {
    static get home() { return i18n.t('home'); }
    static get product() { return i18n.t('product'); }
    static get footerPrivacyPolicy() { return i18n.t('footer.privacy_policy'); }
    static get footerFooterTermsAndConditions() { return i18n.t('footer.footer_terms_and_conditions'); }
    static get footerAllRightsReserved() { return i18n.t('footer.all_rights_reserved'); }
    static get apiErrorsUserNotFound() { return i18n.t('apiErrors.user_not_found'); }
    static get apiErrorsInvalidLogin() { return i18n.t('apiErrors.invalid_login'); }
    static get men() { return i18n.t('men'); }
    static get thumbnail() { return i18n.t('thumbnail'); }
    static get validationPasswordMinLength() { return i18n.t('validation.password_min_length'); }
    static get validationPasswordRequired() { return i18n.t('validation.password_required'); }
    static get validationPasswordMaxLength() { return i18n.t('validation.password_max_length'); }
    static get validationEmailMaxLength() { return i18n.t('validation.email_max_length'); }
    static get validationEmailRequired() { return i18n.t('validation.email_required'); }
    static get validationInvalidEmail() { return i18n.t('validation.invalid_email'); }
    static get loginPageForgotPassword() { return i18n.t('loginPage.forgot_password'); }
    static get loginPageLoginSpreeai() { return i18n.t('loginPage.login_spreeai'); }
    static get loginPageLogIn() { return i18n.t('loginPage.log_in'); }
    static get loginPagePartnerAsks() { return i18n.t('loginPage.partner_asks'); }
    static get loginPageEmail() { return i18n.t('loginPage.email'); }
    static get loginPagePassword() { return i18n.t('loginPage.password'); }
    static get loginPagePartnerAsksDescription() { return i18n.t('loginPage.partner_asks_description'); }
    static get loginPageViewDemoDescription() { return i18n.t('loginPage.view_demo_description'); }
    static get loginPageWelcomeBack() { return i18n.t('loginPage.welcome_back'); }
    static get loginPageCreateAccount() { return i18n.t('loginPage.create_account'); }
    static get loginPageManageAssets() { return i18n.t('loginPage.manage_assets'); }
    static get loginPageViewDemo() { return i18n.t('loginPage.view_demo'); }
    static get loginPageManageAssetsDescription() { return i18n.t('loginPage.manage_assets_description'); }
    static get close() { return i18n.t('close'); }
    static get women() { return i18n.t('women'); }
    static get navbarNavbarLogout() { return i18n.t('navbar.navbar_logout'); }
    static get navbarNavbarSignup() { return i18n.t('navbar.navbar_signup'); }
    static get navbarNavbarCategoryWomen() { return i18n.t('navbar.navbar_category_women'); }
    static get navbarNavbarCategoryMen() { return i18n.t('navbar.navbar_category_men'); }
    static get navbarNavbarSignin() { return i18n.t('navbar.navbar_signin'); }
    static get navbarNavbarManageAccount() { return i18n.t('navbar.navbar_manage_account'); }
    static get protea() { return i18n.t('protea'); }
    static get productDetailProductDetail() { return i18n.t('productDetail.productDetail'); }
    static get productDetailColor() { return i18n.t('productDetail.color'); }
    static get productDetailSize() { return i18n.t('productDetail.size'); }

}
export default Copydeck;