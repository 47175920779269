import { useTranslation } from 'react-i18next';
import CustomLink from '../CustomLink';
import CONSTANTS from '../../Constants';
import { GarmentDTO } from '../../Services/garmentService';
import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import { useCustomNavigation } from '../../Hooks';

interface BreadCrumbProps {
    productData?: GarmentDTO;
    className?: string;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ productData, className }) => {
    const { t } = useTranslation();
    const { category } = useParams();
    const customNavigate = useCustomNavigation();

    const handleCategoryClick = () => {
        const categoryName = productData?.categories[0]?.name || category || '';
        customNavigate(CONSTANTS.SCREEN.CATEGORY, { category: categoryName });
    };

    return (
        <div className="container">
            <nav className={`${styles.breadCrumbs} ${className}`}>
                {(category || productData) && (
                    <CustomLink
                        destinationScreen={CONSTANTS.SCREEN.HOME}
                        copydeckString={t('home')}
                        className={styles.links}
                    />
                )}
                {(category || productData) && (
                    <>
                        <span className={styles.links}>{'>'}</span>
                        <span onClick={handleCategoryClick} className={styles.links}>
                            {t(
                                category?.toLowerCase() ||
                                    (productData?.categories[0]?.name.toLowerCase() as string)
                            )}
                        </span>
                    </>
                )}
                {productData && (
                    <>
                        <span className={styles.links}>{'>'}</span>
                        <span className={styles.links}>{productData.title}</span>
                    </>
                )}
            </nav>
        </div>
    );
};

export default BreadCrumb;
